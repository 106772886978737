import ApiService from "@/core/services/ApiService";

// 新增合約使用者
export function addContractUser(spaceGuid, contractGuid, contractOwnerGuid, userGuid) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Contract/${contractGuid}/ContractOwner/${contractOwnerGuid}/User/${userGuid}/ContractUser`,{}).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 取得合約使用者
export function getContractUser(spaceGuid, contractGuid, contractOwnerGuid): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Space/${spaceGuid}/Contract/${contractGuid}/ContractOwner/${contractOwnerGuid}/ContractUser`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}