import ApiService from "@/core/services/ApiService";
import { ContractOwner } from "../responsesModel/contractOwner";

// 合約簽約
export function checkContractOwnerExist(spaceGuid, contractGuid, userGuid, request):Promise<boolean> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Contract/${contractGuid}/User/${userGuid}/ContractOwner/Exist`, request).then(res => {
            resolve(res.data.result)
        }).catch(err => {
            reject(err)
        })
    })
}


// 合約簽約
export function addContractOwner(spaceGuid, contractGuid, userGuid, request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Contract/${contractGuid}/User/${userGuid}/ContractOwner`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 取得該使用者於時間內已存在的簽約資訊
export function getContractOwnerExist(spaceGuid, contractGuid, userGuid, request):Promise<Array<ContractOwner>> {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Contract/${contractGuid}/User/${userGuid}/ContractOwner/Exist`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}


// 取得空間合約優惠
export function getContractOwner(spaceGuid, contractGuid) {
    return new Promise((resolve, reject) => {
        ApiService.get(`Space/${spaceGuid}/Contract/${contractGuid}/ContractOwner`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 根據時間範圍取得空間合約優惠
export function getContractOwnerByTimeRange(spaceGuid, requset) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/ContractOwner`, requset).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 修改合約優惠
export function modifyContractOwner(spaceGuid, contractGuid, contractOwnerGuid, requset) {
    return new Promise((resolve, reject) => {
        ApiService.patch(`Space/${spaceGuid}/Contract/${contractGuid}/ContractOwner/${contractOwnerGuid}`, requset).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}